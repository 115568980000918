import React, { useEffect }  from 'react';

const VideoJS = (props) => {

  useEffect(() => {
  }, [])

  return (
    <div>
    Video JS
    </div>
  );

};

export default VideoJS;