import React, { useState }  from 'react';
import { Button } from 'antd';
import { Form, Input } from 'antd';
import { useSelector } from "react-redux";
import { Modal } from 'antd';

const MakeRequest = (props) => {

  const user = useSelector(state => state.reducers.user);

  const [showRequest, setShowRequest] = useState(false);
  const [requestSubmitLoading, setRequestSubmitLoading] = useState(false);
  const [requestDescription, setRequestDescription] = useState();

  const [form] = Form.useForm();

  const onSubmit = () => {

    setRequestSubmitLoading(true);

    const d = {
      description: requestDescription,
      name: props.data && props.data.name,
      or_url: props.data && props.data.or_url,
      user: user
    }

    fetch("/api/request", {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(d)
    }).then(res => res.json()).then(res => {
      console.log(res);
      form.resetFields();
      setRequestSubmitLoading(false);
      setShowRequest(false);
    });
  }

  const onSubmitCancel = () => {
    setShowRequest(false);
    setRequestSubmitLoading(false);
  }

  const onSubmitFailed = () => {
    console.log("Submit Failed");
    return false;
  }

  return (
    <div>
      <Button block size="small" type="primary" onClick={() => { setShowRequest(true) }}>
              Make Request
            </Button>
          <Modal
          title="Make Request"
          visible={showRequest}
          onOk={onSubmit}
          onCancel={onSubmitCancel}
          footer={[
            <Button key="back" onClick={onSubmitCancel}>
              Cancel
            </Button>,
            <Button key="submit" loading={requestSubmitLoading} disabled={!requestDescription} type="primary" onClick={onSubmit}>
              Make Request
            </Button>,
          ]}
        >
          <Form
            form={form}
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
            requiredMark={true}
            onValuesChange={(values) => {
              setRequestDescription(values.description);
            }}
          >
            {props.data && (
              <h2>{props.data.name}</h2>
            )}

            <Form.Item
              name="description"
              label={`Request`}
            >
              <Input.TextArea rows={4} placeholder="Please enter a request" />
            </Form.Item>
          </Form>
        </Modal>

          {/*
            <Dropdown overlay={downloadMenu} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Download <DownOutlined />
              </a>
            </Dropdown>
          */}

    </div>
  );
};

export default MakeRequest;