import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store, history } from './data/store';
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <CookiesProvider>
        <App />
        </CookiesProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
