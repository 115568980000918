import React, { useState }  from 'react';
import { withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from 'antd';

import './Login.css'

const Login = (props) => {

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);


  const onSubmit = values => {
    fetch('/api/login', {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        credentials: 'include',
        body: JSON.stringify(values)
      })
      .then(res => res.json())
      .then(result => {
        if (result.error) throw result.error;
        console.log(result);
        dispatch({ type: 'SET_USER', user: typeof result.user === "undefined" ? null : result.user });
        // setSubmitting(false);
        setTimeout(() => {
          props.history.push("/");
        }, 3000);
      })
      .catch(err => {
        setSubmitting(false);
        console.error(err);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="loginForm formWrapper">
      <Form
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
      >
        <p>
          Enter the login code you received in your email
        </p>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input the password you received in your email' }]}
        >
          <Input
          placeholder="password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={submitting} onClick={() => setSubmitting(true)} htmlType="submit">
            Log In
          </Button>
        </Form.Item>
        <p>
          Need a password? <Link to="/">Get a new one</Link>
        </p>
      </Form>
    </div>
  );
};

export default withRouter(Login);