import React, { useEffect, useState } from 'react';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Loader from './Loader.js';

const Users = (props) => {

  const [users, setUsers] = useState();

  useEffect(() => {

    const syncSpreadsheet = async () => {

      // encode google auth json as base 64 string
      // decode google auth json from env var
      if (!process.env.REACT_APP_GOOGLE_AUTH) console.error('No Google Auth found in .env');

      const buffer = Buffer.from(process.env.REACT_APP_GOOGLE_AUTH, 'base64');
      const googleServiceAcctAuth = JSON.parse(buffer.toString('utf-8'));

      const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEETS_ID);
      await doc.useServiceAccountAuth(googleServiceAcctAuth);
      await doc.loadInfo();
      const usersSheet = await doc.sheetsByIndex[0];
      const userRows = await usersSheet.getRows();

      setUsers(userRows);
    }

    syncSpreadsheet();

  }, []);

  if (!users) return(<div className="siteContent"><Loader /></div>);

  return(
    <div className="siteContent">
      {users && users.map((user, i) =>
        <p key={i}>
          email: {user.email}
          <br />
          auth: {user.auth}
          <br />
          created: {user.created}
        </p>)
      }
    </div>
    );
};

export default Users;
