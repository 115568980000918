import React  from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from 'antd';
import { useSelector, useDispatch } from "react-redux";

const Header = (props) => {

  const dispatch = useDispatch();
  const user = useSelector(state => state.reducers.user);

  const logout = async () => {
    let res = await fetch('/api/logout',{
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'content-type': 'application/json' }),
    });
    let data = await res.json();
    console.log(data);
    dispatch({ type: 'SET_USER', user: null });
    props.history.push("/");
  };
  
  const icon = process.env.REACT_APP_ENV === 'development' ? "/img/cFire-icon_dev.png" : "/img/cFire-icon.png";

  return (
    <div className="header">

      <div>
        <Link to="/">
          <img alt="CFire" className="cfire-logo" src={icon} />
        </Link>
      </div>      

      {user && (
        <div className="logout-header">
          {user.email} &nbsp; | &nbsp; <Button type="text" className="logout" onClick={logout}>logout</Button>
        </div>
      )}

    </div>
  );
};

export default withRouter(Header);