import React, { useEffect }  from 'react';
import { withRouter, Link } from "react-router-dom";

const Plyr = (props) => {

  useEffect(() => {
  }, [])

  document.body.style.backgroundColor = "#fff";

  return (
    <div style={{margin:"2em"}}>
      <Link to="/players/vimeo/" target="_blank">Vimeo</Link><br />
      <Link to="/players/videojs/" target="_blank">VideoJS</Link><br />
      <Link to="/players/plyr/" target="_blank">Plyr</Link><br />
    </div>
  );

};

export default withRouter(Plyr);