import React, { useState, useEffect }  from 'react';
import ReactVimeo from '@u-wave/react-vimeo';

import Util from './../../Util'

import './Vimeo.css';

const Vimeo = (props) => {

  const [vimeoID, setVimeoID] = useState(false);
  const url = props.url;

  const [windowDims, setWindowDims] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    fetch("/api/u/" + url).then(res => res.json()).then(res => {
      setVimeoID(res.vimeo_url.split('/').pop());
    });
  }, [url])

  window.addEventListener('resize', Util.debounce((e) => {
    setWindowDims({ width: window.innerWidth, height: window.innerHeight });
  }, 500));

  document.body.style.backgroundColor = "#000000";
  document.body.style.overflow = "hidden";
  document.body.style.margin = "0";
  document.body.style.padding = "0";

  if (!vimeoID) {
    return(null);
  }

  return (
    <div>
    <div className="bgLogo"></div>
    <ReactVimeo
      className="vimeoWrapper"
      video={vimeoID}
      quality="4k"
      background={true}
      width={windowDims.width}
      height={windowDims.height}
      autoplay
    />
    </div>
  );

};

export default Vimeo;