import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import './App.less';

import Login from "./components/Login";
import Register from "./components/Register";
import Header from "./components/Header"
import ContentList from "./components/ContentList/ContentList";
import UrlEdit from "./components/UrlEdit";
import Users from "./components/Users";

// video players
import Players from "./components/Players/Players";
import VimeoPlayer from "./components/Players/Vimeo";
import VideoJSPlayer from "./components/Players/VideoJS";
import PlyrPlayer from "./components/Players/Plyr";

const App = (props) => {

  const user = useSelector(state => state.reducers.user);
  const dispatch = useDispatch();

  useEffect(() => {

    fetch("/api/status").then(res => res.json()).then(res => {
      console.log(res);
    });

    if (!user) {
      fetch('/api/login', {
        headers: new Headers({ 'content-type': 'application/json' }),
        credentials: 'include'
      })
        .then(res => res.json())
        .then(result => {
          dispatch({ type: 'SET_USER', user: typeof result.user === "undefined" ? null : result.user });
        })
    }

  }, [dispatch, user]);

  if (typeof user === "undefined") {
    return(null);
  }

  if (!user) {
    return (
      <Router>
          <Switch>

            <Route exact path={["/", "/register"]}>
              <div className="siteWrapper">
                <Header />
                <Register />
              </div>
            </Route>

            <Route exact path="/login">
              <div className="siteWrapper">
                <Header />
                <Login />
              </div>
            </Route>

            <Route exact path="/u/:url" render={(props) => (
              <VimeoPlayer url={props.match.params.url} />
            )}/>

          </Switch>

      </Router>
    );
  }

  return(
    <Router>
      <Switch>

        <Route exact path={["/", "/urls"]}>
          <div className="siteWrapper">
            <Header />
            <ContentList/>
          </div>
        </Route>

        <Route exact path="/urls/add">
          <div className="siteWrapper">
            <Header />
            <UrlEdit add />
          </div>
        </Route>

        <Route exact path="/urls/edit/:pid36" render={(props) => (
          <div className="siteWrapper">
            <Header />
            <UrlEdit id={props.match.params.pid36} />
          </div>
        )} />

        <Route exact path="/users">
          <div className="siteWrapper">
            <Header />
            <Users />
          </div>
        </Route>

        {/* players */}

        <Route exact path="/u/:url" render={(props) => (
          <VimeoPlayer url={props.match.params.url} />
        )}/>

        <Route exact path="/players">
          <Players />
        </Route>

        <Route exact path="/players/videojs">
          <VideoJSPlayer />
        </Route>

        <Route exact path="/players/vimeo">
          <VimeoPlayer url="GLxn_SJC" />
        </Route>

        <Route exact path="/players/plyr">
          <PlyrPlayer />
        </Route>

      </Switch>
    </Router>
    )
};

export default App;