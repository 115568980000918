import React, { useEffect }  from 'react';

const Plyr = (props) => {

  useEffect(() => {
  }, [])

  return (
    <div>
    Plyr
    </div>
  );

};

export default Plyr;