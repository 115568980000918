import React, { useState, useEffect }  from 'react';
import { useSelector } from "react-redux";
import { Tag, Button } from 'antd';
import { Form, Input } from 'antd';

import { Modal } from 'antd';
import { EditFilled } from '@ant-design/icons';

const ContentRow = (props) => {

  const data = props.data;
  const vimeo_id = data.vimeo_url && data.vimeo_url.split("/").pop();
  const [tags, setTags] = useState([]);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [plays, setPlays] = useState();

  const [showRequest, setShowRequest] = useState(false);
  const [requestSubmitLoading, setRequestSubmitLoading] = useState(false);
  const [requestDescription, setRequestDescription] = useState();

  const user = useSelector(state => state.reducers.user);

  const deleteRow = () => {
    setModalVisible(true);
  }

  const onDeleteOk = () => {
    fetch("/api/urls/delete/" + props.data.id.toString(36), {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(data)
    }).then(res => res.json()).then(res => {
      console.log(res);
      setDeleted(true);
    });
    setModalVisible(false);
  }

  const onDeleteCancel = () => {
    setModalVisible(false);
  }

  const onSubmit = () => {

    setRequestSubmitLoading(true);

    const d = {
      description: requestDescription,
      name: props.data.name,
      or_url: props.data.or_url,
      user: user,
      plays: plays
    }


    fetch("/api/request", {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(d)
    }).then(res => res.json()).then(res => {
      console.log(res);
      setRequestSubmitLoading(false);
      setShowRequest(false);
    });

  }

  const onSubmitCancel = () => {
    setShowRequest(false);
    setRequestSubmitLoading(false);
  }

  const onSubmitFailed = () => {
    console.log("Submit Failed");
    return false;
  }


  useEffect(() => {
    if (vimeo_id) {
      fetch("/api/vimeo/videos/" + vimeo_id).then(res => res.json()).then(res => {
        setPlays(res.stats.plays);
        setName(res.name);
        setDescription(res.description);
        setTags(res.tags.map((t, i) => {
          return (<Tag key={i}>{t.tag}</Tag>);
        }))
      });
    }
  }, [vimeo_id]);

  if (deleted) return(null);

  return (
    <tr>
        <td className="actions">

          {user.authLevel === 1 && (<>

            <Button block size="small" type="primary" onClick={() => { setShowRequest(true) }}>
              Make Request
            </Button>
          <Modal
          title="Make Request"
          visible={showRequest}
          onOk={onSubmit}
          onCancel={onSubmitCancel}
          footer={[
            <Button key="back" onClick={onSubmitCancel}>
              Cancel
            </Button>,
            <Button key="submit" loading={requestSubmitLoading} disabled={!requestDescription} type="primary" onClick={onSubmit}>
              Make Request
            </Button>,
          ]}
        >
          <Form
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
            requiredMark={true}
            onValuesChange={(values) => {
              setRequestDescription(values.description);
            }}
          >
            <h2>{data.name}</h2>

            <Form.Item
              name="description"
              label={`Request`}
            >
              <Input.TextArea rows={4} placeholder="Please enter a request" />
            </Form.Item>
          </Form>
        </Modal>

          {/*
            <Dropdown overlay={downloadMenu} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Download <DownOutlined />
              </a>
            </Dropdown>
          */}

          </>)}

          {user.authLevel === 2 && (<>

          <Button block size="small" icon={<EditFilled />} type="primary"
            href={"/urls/edit/" + data.id.toString(36)}>
          Edit
          </Button>

          <Button block size="small" target="_blank"
            href={data.vimeo_url + "/settings"}>
            Settings
          </Button>

          <Button block size="small"
            onClick={deleteRow}>
            Delete
          </Button>

          <Modal
            title={"Delete"}
            visible={modalVisible}
            onOk={onDeleteOk}
            onCancel={onDeleteCancel}
            okText="Delete"
            cancelText="Cancel"
            footer={[
              <Button key="back" onClick={onDeleteCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={false} onClick={onDeleteOk}>
                Delete
              </Button>,
            ]}
          >
          <p>Are you sure?</p>
          </Modal>

          </>)}

        </td>
        <td className="filterable">
          <a href={"/u/" + data.or_url} target="_blank" rel="noopener noreferrer">
            {data.or_url}
          </a>
        </td>
        <td>
          <a href={"/u/" + data.or_url} target="_blank" rel="noopener noreferrer">
            <img alt={data.name} src={data.thumbnail_url} style={{ maxWidth : "200px" }} />
          </a>
        </td>
        <td className="filterable">
          <strong>{name}</strong>
        </td>
        <td className="filterable">
          {tags}
        </td>
        <td className="filterable description">
          {description}
        </td>
        <td className="plays">
          {plays}
        </td>
        <td className="filterable">
          {data.owner}
        </td>
    </tr>
  );

};

export default ContentRow;